import React from 'react';
import './VerifiedComponent.css'; // Assuming you'll add some basic styling

const VerifiedComponent = () => {
  return (
    <div className="verified-container">
      <h3>Your account has been verified. You can close this tab</h3>
    </div>
  );
};

export default VerifiedComponent;
