import React, { useState } from 'react';
import './IdentityVerification.css';
import Popup from './Popup';


const IdentityVerificationComponent = ({ onSubmit, loading, ws }) => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);  

//   const [phnoDsbl, setphnoDsbl] = useState(false)
//   const [emlDsbl, setemlDsbl] = useState(false)
//   const [loading, setLoading] = useState({ email: false, phoneNumber: false });

  const handleOpenPopup = (event) => {
    setIsPopupOpen(true);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="identity-verification-container">
      <p className='verification-heading'>Identity Verification</p>
      <p className="verification-instructions">      
In order to access our platform, you must complete an account verification. We need this information to help us confirm your identity.

      </p>      
        <button
          className='verify-button'
          onClick={() => handleOpenPopup('phoneNumber')}          
        >
          Verify your Identity
        </button>      
        {isPopupOpen && <Popup onClose={handleClosePopup} ws={ws}/>}
      
    </div>
  );
};

export default IdentityVerificationComponent;
