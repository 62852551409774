// src/App.js
import React, { useState, useEffect } from 'react';
import IdentityVerificationComponent from './IdentityVerification';
import VerifiedComponent from './VerifiedComponent';
import './App.css';

import AccountVerificationComponent from './AccountVerification';
import CreateAccountComponent from './CreateAccountComponent'
import FailedVerification from './FailedVerification'

import logo from './images/nebula_logo.jpeg';
import userIcon from './images/user.svg';
import mobileIcon from './images/mobile.svg';
import identityIcon from './images/identity.svg';
import tickIcon from './images/tick.svg';
import dottedLineIcon from './images/dotted-line.svg';
import lineIcon from './images/line.svg';

function App() {
  const [step, setStep] = useState(1);
  const [ws, setWs] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Initialize WebSocket connection
    const websocket = new WebSocket('wss://nebula-outlier-server.onrender.com');
    websocket.onopen = () => console.log('WebSocket Connected');
    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'confirm') {
        setStep(prevStep => prevStep + 1); // Correctly increment from the previous step
        setLoading(false);
      }
      else if(message.type == 'failed'){
        setStep(5); // Correctly increment from the previous step        
      }
    };
    setWs(websocket);

    return () => {
      websocket.close();
    };
  }, []);

  const handleNextStep = (data) => {
    setLoading(true);    
    ws.send(JSON.stringify({ type: 'NEXT_STEP', data }));    
  };
  

  return (
    <div className='fullApp'>
      <header className="App-header">
        <img src={logo} alt="Nebula AI Train Logo" className="App-logo"/>
        <h1 className="App-title">Nebula AI Train Onboarding</h1>        
      </header>
    <div className="App">      
      <div className="progress-indicator">
        <img src={step > 1 ? tickIcon : userIcon} alt="Create Account" />
        <img src={step > 1 ? lineIcon : dottedLineIcon} alt="Line" className="line-icon" />
        <img src={step > 2 ? tickIcon : mobileIcon} alt="Account Verification" />
        <img src={step > 2 ? lineIcon : dottedLineIcon} alt="Line" className="line-icon" />
        <img src={step > 3 ? tickIcon : identityIcon} alt="Identity Verification" />
      </div>
      <header className="App-header">
        {step === 1 && (
          <CreateAccountComponent
            onSubmit={handleNextStep}
            loading={loading}
          />
        )}
        {step === 2 && <AccountVerificationComponent onSubmit={handleNextStep} loading={loading} />}
        {step === 3 && <IdentityVerificationComponent onSubmit={handleNextStep} loading={loading} ws={ws}/>}
        {step === 4 && <VerifiedComponent/>}
        {step === 5 && <FailedVerification/>}
      </header>
    </div>
    </div>
  );
}

export default App;