import React, { useState } from 'react';
import './CreateAccountComponent.css';

const CreateAccountComponent = ({ onSubmit, loading }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailid: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    dateOfBirth: ''
  });  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="create-account-container">      
      <p className="info-message">
        Please make sure your information is accurate. We will ask you to verify your identity in the next step.
      </p>
      <form className="form-row" onSubmit={handleSubmit}>
        {/* <div className="form-row"> */}
          <div className="input-field-container">
            <label className="input-label">First Name:</label>
            <input
              type="text"
              name="firstName"
              className="input-field"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter first name"
              required
            />
          </div>
          <div className="input-field-container">
            <label className="input-label">Last Name:</label>
            <input
              type="text"
              name="lastName"
              className="input-field"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Enter last name"
              required
            />
          </div>
        {/* </div> */}
        <div className="input-field-container">
          <label className="input-label">Date of Birth:</label>
          <input
            type="date"
            name="dateOfBirth"
            className="input-field"
            value={formData.dateOfBirth}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-field-container">
          <label className="input-label">Email ID</label>
          <input
            type="text"
            name="emailid"
            className="input-field"
            value={formData.emailid}
            onChange={handleChange}
            placeholder="Enter email"
            required
          />
        </div>
        <div className="input-field-container">
          <label className="input-label">Address Line 1:</label>
          <input
            type="text"
            name="address1"
            className="input-field"
            value={formData.address1}
            onChange={handleChange}
            placeholder="Enter address line 1"
            required
          />
        </div>
        <div className="input-field-container">
          <label className="input-label">Address Line 2:</label>
          <input
            type="text"
            name="address2"
            className="input-field"
            value={formData.address2}
            onChange={handleChange}
            placeholder="Enter address line 2"
          />
        </div>
        <div className="input-field-container">
          <label className="input-label">Phone Number:</label>
          <input
            type="tel"
            name="phoneNumber"
            className="input-field"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter phone number"
            required
          />
        </div>
        
        {!loading && (
          <button type="submit" className="otp-submit">
            Submit
          </button>
        )}
        {loading && <div className="loader"></div>}
      </form>
    </div>
  );
};

export default CreateAccountComponent;
