import React, {useState} from 'react';
import './Popup.css'; // Make sure to create a corresponding CSS file


// Add your images paths here
import logo from './images/nebula_logo.jpeg'; // Update the path accordingly
import verificationImage from './images/verficimage.png'; // Update the path accordingly
import personaBadge from './images/badge.png'; // Update the path accordingly


const Popup = ({ onClose, ws }) => {

    const [step, setStep] = useState(0); // 0 for the initial state, 1 for after clicking "Begin Verifying"
    const [email, setEmail] = useState('');
    const [phno, setphno] = useState('')
    const [loading, setLoading] = useState('')

    const handleBeginVerifying = () => {
        setStep(1); 
    }

    const handleBack = () => {
        setStep((step)=>step-1); // Go back to the previous step
    };

    const handleSendEmail = () =>{
        alert("Link sent to your email. Please stay on this page until verification is complete")
        let data = {'email': email}
        ws.send(JSON.stringify({ type: 'NEXT_STEP', data}));
        setLoading(true);
    }

    const handleSendSMS = () =>{
        alert("Link sent to your mobile number. Please stay on this page until verification is complete")
        let data = {'phno': phno}
        ws.send(JSON.stringify({ type: 'NEXT_STEP', data }));
        setLoading(true);
    }

    return (
        <div className="popup-overlay">
        <div className="popup">
            <button className="popup-close" onClick={onClose}>&times;</button>
            {step>=1 && <button className="popup-back" onClick={handleBack}>&lt;</button>}
            {/* <div className="popup-header">
            <img src={logo} alt="Outlier Logo" className="popup-logo"/>
            </div> */}

            {step == 0 && (
                <div>
                <p className="popup-message">
                Hi. Thank you for your interest in our platform! We need some information to help us confirm your identity.
                </p>
                {/* Placeholder for the image */}
                <img src={verificationImage} alt="Verification" className="verification-image"/>
                <p className="popup-consent">
                By clicking the button below, you consent to Persona, our vendor, collecting, using, and utilizing their service providers to process your biometric information to verify your identity, identify fraud, and improve Persona’s platform in accordance with its <a href="https://withpersona.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Your biometric information will be stored for no more than 3 years.
                </p>
                <button className="popup-button" onClick={handleBeginVerifying}>Begin Verifying</button>
                <div className="popup-footer">
                <img src={personaBadge} alt="Secured with Persona" className="persona-badge"/>
                </div>
                </div>
            ) }

            {step == 1 && (
                <div>                
                <p className="popup-message">To verify your identity, we need access to a camera. We’ll send you a secure link for continuing on your phone. No app download is required.</p>
                <img src={verificationImage} alt="Verification" className="verification-image"/>
                
                <div className="email-input-group">
                <label htmlFor="email" className="email-input-label">Email address</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="you@example.com"
                    className="email-input-field"
                />
                </div>
                <button className="popup1-button" onClick={handleSendEmail}>Send Email</button>

                
                <label htmlFor="email" className="email-input-label">Mobile Number</label>
                <input
                    type="phno"
                    id="phno"
                    name="phno"
                    value={phno}
                    onChange={(e) => setphno(e.target.value)}
                    placeholder="+1"
                    className="email-input-field"
                />
                <button className="popup1-button" onClick={handleSendSMS}>Send SMS</button>
                    <br></br>
                {loading && <div className="loader"></div>}
                <div className="popup-footer">
                <img src={personaBadge} alt="Secured with Persona" className="persona-badge"/>
                </div>
              </div>
            ) }
            
        </div>
        </div>
    );
};

export default Popup;