import React from 'react';
import './VerifiedComponent.css'; // Assuming you'll add some basic styling

const FailedVerification = () => {
  return (
    <div className="verified-container">
      <h3>Sorry, your account verification has been failed.</h3>
    </div>
  );
};

export default FailedVerification;
