import React, { useState } from 'react';
import './AccountVerification.css';

const AccountVerificationComponent = ({ onSubmit, loading }) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));

  const handleChange = (event, position) => {
    const updatedOTP = [...otp];
    updatedOTP[position] = event.target.value.slice(0, 1); // Ensure only one character per box
    setOtp(updatedOTP);

    // Auto-focus to the next field if there's a next field and current input has a value
    if (position < 5 && event.target.value) {
      const nextField = document.getElementById(`otp-${position + 1}`);
      if (nextField) {
        nextField.focus();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(otp.join(''));
  };

  return (
    <form className="otp-container" onSubmit={handleSubmit}>
      {/* <div> */}
      <p className="otp-instruction">Please enter the OTP received on your mobile.</p>
      <div className="otp-inputs-container">
        {otp.map((value, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            className="otp-input"
            onChange={(e) => handleChange(e, index)}
            value={value}
            pattern="[0-9]"
            id={`otp-${index}`}
            autoComplete="off"
            disabled={loading}
            required
          />
        ))}
      </div>
      
      {!loading && (
          <button type="submit" className="otp-submit">
            Submit
          </button>
        )}
        {loading && <div className="loader"></div>}
    </form>
  );
};

export default AccountVerificationComponent;
